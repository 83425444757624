import { getAccessTokenMSAL } from "../fallback-msal/fallbackAuthTaskpane";
import { getMessage } from "./message-helper";

// Default SSO settings for acquiring access tokens.
const defaultSSO = {
  allowSignInPrompt: true,
  allowConsentPrompt: true,
  // forMSGraphAccess: true, // Leave commented during development testing (sideload) or you get a 13012 error from getAccessToken.
};

// NOTICE: Caching lastToken is not best practice
// This method is only a work-around to due with error 13013 about API throttled temporarily after 4-5 calls
// This method should be solved completely in future
let lastToken = null;

export async function callMiddleWareAPI(method, url, isRetry = false, requestBody = null) {
  // Reuse accessToken
  let accessToken = null;
  if (lastToken != null) {
    accessToken = lastToken;
  } else {
    const options = JSON.parse(JSON.stringify(defaultSSO));
    try {
      accessToken = await OfficeRuntime.auth.getAccessToken(options);
    } catch (error) {
      console.log(getMessage("0001"));
      accessToken = await getAccessTokenMSAL();
    }
    lastToken = accessToken;
  }

  // Call the REST API on our web server.
  let fetchOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };
  if (requestBody) {
    fetchOptions.body = JSON.stringify(requestBody);
  }

  try {
    let responseData = await fetch(url, fetchOptions);
    if (!responseData.ok) {
      // console.log(`${getMessage("0003")}: ${responseData.status}`);
      // If error happened with current token, retry with new token
      if (!isRetry) {
        if (responseData.status == 403) {
          localStorage.clear();
          lastToken = await getAccessTokenMSAL();
          return callMiddleWareAPI(method, url, true, requestBody);
        } else {
          lastToken = null;
          return callMiddleWareAPI(method, url, true, requestBody);
        }
      }
    }
    let responseJson = await responseData.json();

    if (responseJson && !responseJson.error) {
      return responseJson;
    } else if (responseJson && responseJson.type === "TokenExpiredError" && !isRetry) {
      // Handle token expire case: Reset lastToken to clear cache
      console.log(getMessage("0002"));
      lastToken = null;
      return callMiddleWareAPI(method, url, true, requestBody);
    } else if (responseJson && responseJson.error == "invalid_grant") {
      // Handle not consent: MSAL Fallback
      console.log(getMessage("0001"));
      try {
        let token = await getAccessTokenMSAL();
        lastToken = token;
        return callMiddleWareAPI(method, url, true, requestBody);
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      // Unexpected cases
      console.error(responseJson);
      return null;
    }
  } catch (error) {
    // Handle the error here
    // console.log(`${getMessage("0003")}: ${error}`);
    // If error happened with current token, retry with new token
    if (!isRetry) {
      lastToken = null;
      return callMiddleWareAPI(method, url, true, requestBody);
    }
  }
}
