const FPT_DOMAIN = "@fpt.com";

// Utilities: write log
export function writeLog(log) {
  // eslint-disable-next-line no-undef
  if (console) {
    // eslint-disable-next-line no-undef
    console.log(log);
  }
}
// Utilities: Transform MS Graph's user data to project data
export function extractUserData(rawData) {
  try {
    const rawUserData = rawData.value[0].hitsContainers[0].hits[0].resource;
    return {
      name: rawUserData.displayName,
      account: rawUserData.userPrincipalName.split("@")[0],
      company: rawUserData.companyName,
      jobTitle: rawUserData.jobTitle,
      email: rawUserData.userPrincipalName,
      department: rawUserData.department,
    };
  } catch (e) {
    writeLog(JSON.stringify(e));
    return undefined;
  }
}
// Utilities: Compare two object
// TODO: using better compare method
export function compareObj(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}
// Utilities: Find
export function findLatestUser(originList, updatedList) {
  const latestUser = updatedList.find((u) => originList.findIndex((ou) => ou === u) < 0);
  return latestUser;
}
// Utilities: Check if email is in FPT domain
export function checkFPTDomain(email) {
  const trimmedEmail = email.trim();
  const splitArr = trimmedEmail.split(FPT_DOMAIN);
  if (splitArr.length !== 2) {
    return false;
  }
  if (splitArr[1].length !== 0) {
    return false;
  }
  return true;
}
// Utilities: add user data to group list
export function appendToGroup(groupList, userData) {
  const existedGroupIndex = groupList.findIndex((g) => g.name === userData.jobTitle);
  if (existedGroupIndex < 0) {
    groupList.push({
      name: userData.jobTitle,
      data: [userData],
    });
  } else {
    groupList[existedGroupIndex].data.push(userData);
  }
}
// Utilities: show or hide element
export function showOrHideElement(elem, isShow) {
  if (isShow) {
    if (elem.classList.contains("display-none")) {
      elem.classList.remove("display-none");
    }
    return;
  }
  if (!elem.classList.contains("display-none")) {
    elem.classList.add("display-none");
  }
}
// Utilities: try add class
export function addClassIfNotExist(elem, className) {
  if (!elem.classList.contains(className)) {
    elem.classList.add(className);
  }
}
// Utilities: try remove class
export function removeClassIfExist(elem, className) {
  if (elem.classList.contains(className)) {
    elem.classList.remove(className);
  }
}
