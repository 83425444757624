// This file copied and modified from https://github.com/Azure-Samples/ms-identity-javascript-tutorial/blob/main/1-Authentication/1-sign-in/App/authConfig.js

const clientId = 'f1e5085b-b83d-4462-82b2-147fe28602b4';
const accessScope = 'api://' + window.location.host + '/' + clientId + '/access_as_user';

/**
   * Scopes you add here will be prompted for user consent during sign-in.
   * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
   * For more information about OIDC scopes, visit: 
   * https://learn.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
   * 
   */
const loginRequest = {
"scopes": [accessScope],
"extraScopesToConsent": ["user.read", "people.read"]
};


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */

 const msalConfig = {
    auth: {
      clientId: clientId, // This is the ONLY mandatory field that you need to supply.
      authority: "https://login.microsoftonline.com/f01e930a-b52e-42b1-b70f-a8882b5d043b", // Defaults to "https://login.microsoftonline.com/f01e930a-b52e-42b1-b70f-a8882b5d043b"
      redirectUri: "https://peoplegraph.fpt.com/dialog.html", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
      //postLogoutRedirectUri: "https://peoplegraph.fpt.com/signout", // Simply remove this line if you would like navigate to index page after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
      response_type: "access_token"
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  };
  
  
  
  /**
   * An optional silentRequest object can be used to achieve silent SSO
   * between applications by providing a "login_hint" property.
   */
  
  // const silentRequest = {
  //   scopes: ["openid", "profile"],
  //   loginHint: "example@domain.net"
  // };
  
  // exporting config object for jest
  if (typeof exports !== 'undefined') {
    module.exports = {
      msalConfig: msalConfig,
    };
  }
  
const myMSALObj = new msal.PublicClientApplication(msalConfig);
Office.initialize = async function () {
  if (Office.context.ui.messageParent) {
    try {
      const response = await myMSALObj.handleRedirectPromise();
      handleResponse(response);
    } catch (error) {
      console.error(error);
      Office.context.ui.messageParent(
        JSON.stringify({ status: "error", error: error.message }),
        { targetOrigin: window.location.origin }
      );
    }
  }
};

function handleResponse(response) {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response !== null) {
    Office.context.ui.messageParent(
      JSON.stringify({ status: "success", result: response.accessToken, accountId: response.account.homeAccountId }),
      { targetOrigin: window.location.origin }
    );
  } else {
    //log in
    myMSALObj.loginRedirect(loginRequest);
  }
}
